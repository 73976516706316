import React, { useState, useEffect, Fragment } from 'react';

import { Form } from 'react-final-form';
import {
  useDataProvider,
  useRefresh,
  useNotify,
  Button,
  required,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import { useStyles } from '../modal.styles';
import clsx from 'clsx';
import {
  ReferenceInput,
  FormTextField,
  SelectComponent,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  minDate,
  maxDate,
  RadioInput,
} from '../modalForms';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { validateDate } from './inputs';
import { constants } from '../../../../utils';
import { GroupFacilityToggle } from '../../../common';

const { caseTypes } = constants;
const noDateFilters = ['staff_caseload', 'payer_exceptions'];
const noFacilityReports = [
  'staff_caseload',
  'retroactive_staff_caseload',
  'payer_exceptions',
];
const staffCaseloadReports = ['staff_caseload', 'retroactive_staff_caseload'];
const taskReports = ['tasks'];
export const BulkReportsForm = ({ handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const baseURL = 'reports/bulk';

  const [formData, setFormData] = useState({
    sum_facilities: 'false',
    details: 'false',
    is_completed: 'false',
    facility_ids: [],
    group_ids: [],
  });
  const [selectGroup, selectGroupToggle] = useState(false);
  const [groupCaseManager, selectCaseManagerToggle] = useState(false);
  useEffect(() => {
    selectGroup
      ? setFormData(f => ({ ...f, facility_ids: [] }))
      : setFormData(f => ({ ...f, group_ids: [] }));
  }, [selectGroup]);

  const onSubmit = value => {
    return dataProvider
      .getBulk(baseURL, {
        group_type: groupCaseManager === true ? 'case_manager' : 'facility',
        is_completed: value.is_completed === 'true',
        ...value,
      })
      .then(() => {
        notify('Thanks! An email will be sent with the requested reports.');
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (
      formData.report_type &&
      formData.report_type.indexOf('higher_level') > -1
    ) {
      if (name === 'start_date') {
        value = format(startOfMonth(new Date(`${value} 00:00`)), 'yyyy-MM-dd');
      }
      if (name === 'end_date') {
        value = format(endOfMonth(new Date(`${value} 00:00`)), 'yyyy-MM-dd');
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='reports'
                    customOnChange={customOnChange}
                    name='report_type'
                    label='Select report'
                    validate={required()}
                    required
                    fullWidth
                    setFormData={setFormData}
                    options={{
                      filter: { bulk: true },
                      sort: { field: 'name', order: 'ASC' },
                    }}
                    style={{ marginRight: 0 }}
                  />
                </div>
                {taskReports.includes(formData.report_type) && (
                  <RadioInput
                    row
                    name='is_completed'
                    customOnChange={customOnChange}
                    value={formData.is_completed}
                    label=''
                    radios={[
                      {
                        label: 'Open Tasks',
                        value: 'false',
                      },
                      {
                        label: 'Closed Tasks',
                        value: 'true',
                      },
                    ]}
                  />
                )}
                {!noDateFilters.includes(formData.report_type) && (
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      name='start_date'
                      customOnChange={customOnChange}
                      valueModifiedOnChange={true}
                      type='date'
                      label='Start date'
                      required={values.report_type !== 'missing_auth'}
                      validate={
                        values.report_type === 'missing_auth'
                          ? false
                          : values.report_type === 'triple_check'
                          ? composeValidators([
                              maxDate(
                                format(new Date(), 'yyyy-MM-dd'),
                                "Report can't be run for future dates",
                              ),
                            ])
                          : required()
                      }
                      max={
                        values.report_type === 'triple_check'
                          ? format(new Date(), 'yyyy-MM-dd')
                          : undefined
                      }
                    />
                    <FormTextField
                      name='end_date'
                      customOnChange={customOnChange}
                      valueModifiedOnChange={true}
                      type='date'
                      label='End date'
                      required={values.report_type !== 'missing_auth'}
                      validate={
                        values.report_type === 'missing_auth'
                          ? false
                          : values.report_type === 'triple_check'
                          ? composeValidators([
                              maxDate(
                                format(new Date(), 'yyyy-MM-dd'),
                                "Report can't be run for future dates",
                              ),
                            ])
                          : (getEndDateValidators(values), required())
                      }
                      max={
                        values.report_type === 'triple_check'
                          ? format(new Date(), 'yyyy-MM-dd')
                          : undefined
                      }
                    />
                  </div>
                )}
                {!noFacilityReports.includes(formData.report_type) && (
                  <Fragment>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <GroupFacilityToggle
                        selectGroup={selectGroup}
                        selectGroupToggle={selectGroupToggle}
                        facilityLabel='Facilities'
                        groupLabel='Facility Groups'
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      {selectGroup && (
                        <ReferenceInput
                          reference='facility-groups'
                          customOnChange={customOnChange}
                          name='group_ids'
                          label='Select group'
                          validate={required()}
                          required
                          multiple
                          renderWith='chip'
                          fullWidth
                          setFormData={setFormData}
                          perPage={500}
                          selectAll
                          options={{
                            filter: {},
                            sort: { field: 'name', order: 'ASC' },
                          }}
                          style={{ marginRight: 0 }}
                        />
                      )}
                      {!selectGroup && (
                        <ReferenceInput
                          reference='facilities/list'
                          customOnChange={customOnChange}
                          name='facility_ids'
                          label='Select facilities'
                          validate={required()}
                          required
                          multiple
                          fullWidth
                          setFormData={setFormData}
                          perPage={500}
                          selectAll
                          options={{
                            filter: { bulk: true },
                            sort: { field: 'name', order: 'ASC' },
                          }}
                          style={{ marginRight: 0 }}
                        />
                      )}
                    </div>

                    {formData.report_type && (
                      <RadioInput
                        name='sum_facilities'
                        className={classes.radio}
                        customOnChange={customOnChange}
                        value={formData.sum_facilities}
                        validate={required()}
                        label=''
                        radios={[
                          {
                            label: 'Sum all facilities in one report',
                            value: 'true',
                          },
                          {
                            label: 'Run separate reports for each facility',
                            value: 'false',
                          },
                        ]}
                      />
                    )}
                    {formData.report_type &&
                      formData.report_type.indexOf('higher_level') === -1 && (
                        <div className={clsx(classes.inputContainerWrap)}>
                          <SelectComponent
                            customOnChange={customOnChange}
                            label='Select case type'
                            required
                            name='division'
                            validate={required()}
                            choices={caseTypes}
                            fullWidth
                            style={{ marginRight: 0 }}
                          />
                        </div>
                      )}
                  </Fragment>
                )}

                {staffCaseloadReports.includes(formData.report_type) && (
                  <Fragment>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <GroupFacilityToggle
                        selectGroup={groupCaseManager}
                        selectGroupToggle={selectCaseManagerToggle}
                        facilityLabel='Facilities'
                        groupLabel='Case Managers'
                      />
                    </div>
                    {formData.report_type === 'staff_caseload' && (
                      <RadioInput
                        name='details'
                        className={classes.radio}
                        customOnChange={customOnChange}
                        value={formData.details}
                        validate={required()}
                        label=''
                        radios={[
                          {
                            label: 'Caseload Summary',
                            value: 'false',
                          },
                          {
                            label: 'Detailed Caseload',
                            value: 'true',
                          },
                        ]}
                      />
                    )}
                  </Fragment>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.exportButton}
                  type='submit'
                  label='Export'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
function getEndDateValidators(values, type) {
  const validators = [
    validateDate(),
    minDate(values['start_date'], 'End date cannot be before the start date'),
  ];
  return composeValidators(validators);
}
