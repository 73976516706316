import React, { Fragment } from 'react';
import clsx from 'clsx';
import { TextField, SimpleShowLayout, FunctionField } from 'react-admin';
import { Chip, Link, Divider } from '@material-ui/core';
import { useStyles } from './payers.styles';

export const GlobalGeneralInfo = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          paddingBottom: 20,
          paddingTop: 20,
        }}
      >
        <div>
          <SimpleShowLayout {...props}>
            <TextField source='name' label='' className={classes.title} />
            <TextField source='parent_company' label='' />
            <FunctionField
              source='address'
              label='Claims address'
              render={record => {
                const { remit_address, city, state, zip } = record || {};
                return (
                  <div>
                    {remit_address || ''}
                    <br />
                    {(city || '') + ', ' + (state || '') + ' ' + (zip || '')}
                  </div>
                );
              }}
            />{' '}
          </SimpleShowLayout>
        </div>

        <Divider orientation='vertical' variant='middle' flexItem />
        <div>
          <SimpleShowLayout>
            <FunctionField
              source='lob'
              label=''
              className={clsx(classes.showFullWidth)}
              headerClassName={classes.showItemLabel}
              render={record => {
                const { line_of_businesses = [] } = record;
                return (
                  <div className={classes.chips}>
                    {line_of_businesses.map(lob => {
                      const { id, payer_type } = lob;
                      return (
                        <Fragment>
                          <Chip
                            color='primary'
                            key={id}
                            label={payer_type}
                            className={classes.chip}
                          />
                          <div
                            style={{
                              backgroundColor: 'white',
                              paddingRight: 2,
                              paddingLeft: 2,
                              paddingBottom: 30,
                              marginLeft: 0,
                              borderRadius: 0,
                            }}
                          />
                        </Fragment>
                      );
                    })}
                  </div>
                );
              }}
            />
          </SimpleShowLayout>
          <span style={{ display: 'flex' }}>
            <SimpleShowLayout className={classes.showGlobalLayout} {...props}>
              <FunctionField
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
                source='website'
                label='Website'
                render={record => {
                  const { website } = record;
                  return website ? (
                    <Link
                      href={website}
                      target='_blank'
                      rel='noopener noreferrer'
                      underline='none'
                    >
                      {website}
                    </Link>
                  ) : null;
                }}
              />
              <TextField
                source='pre_auth_eligibility_contact'
                label='Pre-auth/eligibility contact'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
              />
              <TextField
                source='submit_terms'
                label='Claim submission terms'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
              />
              <TextField
                source='payment_terms'
                label='Claim payment terms'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
              />
              <TextField
                source='seek_payer_code'
                label='Seek ID'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
              />
            </SimpleShowLayout>
          </span>
        </div>
      </div>
    </Fragment>
  );
};
