import React, { useState, Fragment } from 'react';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent, FormGroup } from '@material-ui/core';
import {
  ReferenceInput,
  // SelectComponent,
  handleNumbers,
  getDateInputValue,
  CheckboxInput,
  // composeValidators,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const BulkReassignUsers = ({
  caseId,
  record = {},
  isEdit,
  handleClose,
  isPlannedDischarge,
  closeAside,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState();

  const onSubmit = value => {
    return dataProvider
      .create('reassign', { data: { ...value } })
      .then(({ data }) => {
        notify('form.created');
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {/* <div className={clsx(classes.inputContainerWrap)}> */}
                <ReferenceInput
                  key={values.facility_id}
                  customOnChange={customOnChange}
                  label='Case manager'
                  name='old_user_id'
                  validate={required()}
                  required
                  reference='facility-users/list'
                  fullWidth
                  shouldFetchMore
                  options={{
                    filter: { facilityId: values.facility_id, inactive: true },
                  }}
                />
                <ReferenceInput
                  key={values.old_user_id}
                  customOnChange={customOnChange}
                  label='Facility'
                  name='facility_id'
                  reference='facilities/list'
                  validate={required()}
                  required
                  fullWidth
                  options={{
                    filter: { caseManagerId: values.old_user_id },
                  }}
                  perPage={500}
                />
                {/* </div> */}
                <FormGroup row>
                  <CheckboxInput
                    name='tasks'
                    customOnChange={customOnChange}
                    label='Reassign Tasks'
                    checked={values.tasks}
                  />
                  <CheckboxInput
                    name='auths'
                    customOnChange={customOnChange}
                    label='Reassign Auths'
                    checked={values.auths}
                  />
                </FormGroup>
                {values.tasks && (
                  <Fragment>
                    <p className={classes.sectionSubHeader}>Tasks</p>
                    <FormGroup row>
                      <CheckboxInput
                        name='active_tasks'
                        customOnChange={customOnChange}
                        label='Active Tasks'
                        checked={values.active_tasks}
                      />
                      <CheckboxInput
                        name='completed_tasks'
                        customOnChange={customOnChange}
                        label='Completed Tasks'
                        checked={values.completed_tasks}
                      />
                    </FormGroup>
                  </Fragment>
                )}
                {values.auths && (
                  <Fragment>
                    <p className={classes.sectionSubHeader}>Authorizations</p>
                    <FormGroup row>
                      <CheckboxInput
                        name='active_auths'
                        customOnChange={customOnChange}
                        label='Active Auths'
                        checked={values.active_auths}
                      />
                      <CheckboxInput
                        name='schedule_auths'
                        customOnChange={customOnChange}
                        label='With active update schedule'
                        checked={values.schedule_auths}
                      />
                      <CheckboxInput
                        name='all_auths'
                        customOnChange={customOnChange}
                        label='All Auths'
                        checked={values.all_auths}
                      />
                    </FormGroup>
                  </Fragment>
                )}
                <ReferenceInput
                  key={values.facility_id + 'assignTo'}
                  customOnChange={customOnChange}
                  label='Assign To'
                  name='new_user_id'
                  validate={required()}
                  required
                  reference='facility-users/list'
                  fullWidth
                  options={{
                    filter: { create: true, facilityId: values.facility_id },
                  }}
                  shouldFetchMore
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 20 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
