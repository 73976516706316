import React, { useState, useEffect, Fragment } from 'react';

import { Form } from 'react-final-form';
import {
  required,
  email,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent, FormGroup } from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  // SelectComponent,
  CheckboxInput,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  minLength,
  validatePhoneOnSubmit,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

const ROLES_OTHER_ID = 13;

export const FacilityContacts = ({
  record = {},
  isEdit,
  payerFacilityId,
  handleClose,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (isEdit && record) {
      const fields = sanitizeFields(record);
      setFormData(f => ({
        ...f,
        ...fields,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    const { phone, fax } = value;
    if (isEdit) {
      return dataProvider
        .update('facility-contacts', {
          id: record.id,
          data: {
            ...value,
            phone: validatePhoneOnSubmit(phone),
            fax: validatePhoneOnSubmit(fax),
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('facility-contacts', {
          data: {
            ...value,
            phone: validatePhoneOnSubmit(phone),
            fax: validatePhoneOnSubmit(fax),
          },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'contact_role_id') {
      if (value !== ROLES_OTHER_ID) {
        setFormData({ ...formData, [name]: value, contact_role_other: null });
        return;
      }
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={classes.inputContainerWrap}>
                  <FormTextField
                    name='first_name'
                    label='First Name'
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                  />
                  <FormTextField
                    name='last_name'
                    label='Last Name'
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                  />
                </div>
                <div className={classes.inputContainerWrap}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='phone'
                    type='text'
                    format='phone'
                    label='Phone'
                    validate={composeValidators([
                      minLength(10, 'Invalid phone number'),
                    ])}
                  />
                  <FormTextField
                    customOnChange={customOnChange}
                    name='phone_ext'
                    type='text'
                    label='EXT.'
                  />
                </div>
                <div className={classes.inputContainerWrap}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='fax'
                    type='text'
                    format='phone'
                    label='Fax'
                    validate={composeValidators([
                      minLength(10, 'Invalid phone number'),
                    ])}
                  />
                  <div style={{ flex: '1 0 160px' }} />
                </div>
                <FormTextField
                  customOnChange={customOnChange}
                  name='email'
                  type='email'
                  label='Email'
                  validate={composeValidators([email(), required()])}
                  required
                  fullWidth
                />
                <ReferenceInput
                  reference='contact-roles/list'
                  customOnChange={customOnChange}
                  name='contact_role_id'
                  label='Role'
                  validate={required()}
                  required
                  fullWidth
                />
                {values.contact_role_id === ROLES_OTHER_ID && (
                  <FormTextField
                    name='contact_role_other'
                    label='Role'
                    customOnChange={customOnChange}
                    fullWidth
                  />
                )}
                <FormGroup>
                  <CheckboxInput
                    name='is_main'
                    customOnChange={customOnChange}
                    label='Main Contact'
                    checked={formData.is_main}
                  />
                  <CheckboxInput
                    name='gets_nomnc'
                    customOnChange={customOnChange}
                    label='Gets Nomnc'
                    checked={formData.gets_nomnc}
                  />
                </FormGroup>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

function sanitizeFields(record) {
  const {
    id,
    contact_role,
    created_at,
    deleted_at,
    updated_at,
    facility_id,
    ...rest
  } = record;
  return { ...rest };
}
