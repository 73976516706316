import { makeStyles } from '@material-ui/core';
// import { styles } from '../../design';
export const useStyles = makeStyles(theme => ({
  container: {
    boxSizing: 'border-box',
    fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
    paddingRight: 50,
    paddingLeft: 50,
    '& p': {
      marginTop: 5,
      marginBottom: 5,
      fontSize: 13,
    },
  },
  options: {
    display: 'flex',
    fontSize: 15,
    '& p': {
      width: '20%',
      margin: 0,
      textAlign: 'center',
    },
  },
  row: {
    display: 'flex',
  },
  shaded_column: {
    width: '42%',
    padding: '3px 10px',
    height: 50,
    '& p': {
      fontSize: 18,
    },
  },
  box: {
    width: '100%',
    height: 250,
    border: '1px solid black',
    padding: 16,
    boxSizing: 'border-box',
    resize: 'none',
    overflowY: 'hidden',
  },
  column: {
    width: '44%',
    margin: '-3px',
  },
  space: {
    width: '12%',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 20,
  },
  inputRoot: {
    marginBottom: 12,
  },
  paper: {
    zIndex: 3,
    marginTop: 0,
  },
}));
