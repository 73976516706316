import React, { useState, useEffect } from 'react';
import { format, parse } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { useNotify } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import { normalizePhone } from './modals/modalForms/inputs';
import { CloseIcon, SaveButton } from '../../design';
import { authPost, authGet } from '../../server';
import { Autocomplete } from '../../components/wrappers';
import { fileManagementActions } from '../../state/actions';
import { Loading } from './Loading';
import { useStyles } from './coverLetter.styles';

export function CoverLetter({
  facility = {},
  resident = {},
  payers = [],
  faxNumData = {},
  comment: prevComment,
  resident_info,
  pageCount,
  caseId,
  open,
  onClose,
  setCoverLetterDocId,
  coverLetterDocId,
}) {
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const firstName = useSelector(state => state.user.profile.first_name);
  const lastName = useSelector(state => state.user.profile.last_name);
  const { full_name, dob: _dob } = resident;

  const dob = _dob && parse(_dob, 'yyyy-MM-dd', new Date());
  const dobFormatted = dob && 'DOB: ' + format(dob, 'M/d/Y');

  const payer = payers.find(p => p.payers_name === faxNumData.company);
  const isFacilityRep = facility?.name === faxNumData.company;
  const residentIdentifier = isFacilityRep
    ? resident.facility_resident_id
    : payer?.residents_insurance_id;
  const residentIdentifierLabel = isFacilityRep ? 'Resident ID' : 'Member ID';
  const residentIdentifierFormatted =
    residentIdentifier && residentIdentifierLabel + ': ' + residentIdentifier;

  const {
    address,
    city,
    state,
    zip,
    sender_fax,
    cm_phone,
    cm_extension,
    name,
  } = facility;
  const {
    company,
    fax: faxNum,
    name: faxName,
    phone: companyPhone,
    phone_ext: companyExt,
  } = faxNumData;

  const [comment, setComment] = useState(prevComment ?? '');
  const [residentInfo, setResidentInfo] = useState(resident_info ?? '');
  const [submitting, setSubmitting] = useState(false);

  const onResidentInfoChange = e => {
    const { value } = e.target;
    if (value?.length <= 30) {
      setResidentInfo(value);
    }
  };

  const submit = async () => {
    setSubmitting(true);
    const {
      cm_phone: _cm_phone,
      sender_fax: _sender_fax,
      ...restFacility
    } = facility;
    const { phone: _phone, ...restFaxData } = faxNumData;
    const payload = {
      facility: {
        ...restFacility,
        cm_phone: normalizePhone(_cm_phone),
        sender_fax: normalizePhone(_sender_fax),
      },
      resident,
      faxNumData: {
        ...restFaxData,
        id: normalizePhone(faxNum),
        phone: normalizePhone(_phone),
      },
      comment,
      resident_info: residentInfo,
      pageCount,
      userFirstName: firstName,
      userLastName: lastName,
      prev_document_id: coverLetterDocId,
      create: true,
    };
    const response = await authPost(`/cases/${caseId}/cover-letter`, payload);
    setSubmitting(false);
    const { data, error } = response;
    if (error) {
      return notify(error.message, 'warning');
    }
    const { document_id } = data;
    if (coverLetterDocId) {
      dispatch(
        fileManagementActions.removeEmailFileId(parseInt(coverLetterDocId)),
      );
    }
    dispatch(fileManagementActions.addEmailFileIds([document_id]));
    setCoverLetterDocId(`${document_id}`);
    notify('Successfully generated a cover letter');
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth='lg'
      fullWidth
      onClose={reason => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        Fax Cover Letter{' '}
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <h2 style={{ textAlign: 'center' }}>{name}</h2>
          <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
            FACSIMILE TRANSMITTAL SHEET
          </p>
          <div className={classes.row}>
            <div className={classes.column}>
              <p>TO:</p>
            </div>
            <div className={classes.space}></div>
            <div className={classes.column}>
              <p>FROM:</p>
            </div>
          </div>
          <div className={classes.row}>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{faxName}</p>
            </div>
            <div
              className={classes.space}
              style={{ backgroundColor: 'white' }}
            ></div>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{`${lastName}, ${firstName}`}</p>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className={classes.row}>
            <div className={classes.column}>
              <p>COMPANY:</p>
            </div>
            <div className={classes.space}></div>
            <div className={classes.column}>
              <p>DATE:</p>
            </div>
          </div>
          <div className={classes.row}>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{company}</p>
            </div>
            <div
              className={classes.space}
              style={{ backgroundColor: 'white' }}
            ></div>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{format(new Date(), 'MM/dd/yyyy')}</p>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className={classes.row}>
            <div className={classes.column}>
              <p>FAX NUMBER:</p>
            </div>
            <div className={classes.space}></div>
            <div className={classes.column}>
              <p>TOTAL NO. OF PAGES:</p>
            </div>
          </div>
          <div className={classes.row}>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{normalizePhone(faxNum)}</p>
            </div>
            <div
              className={classes.space}
              style={{ backgroundColor: 'white' }}
            ></div>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{pageCount}</p>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className={classes.row}>
            <div className={classes.column}>
              <p>PHONE NUMBER:</p>
            </div>
            <div className={classes.space}></div>
            <div className={classes.column}>
              <p>SENDER'S PHONE NUMBER:</p>
            </div>
          </div>
          <div className={classes.row}>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{`${companyPhone ? normalizePhone(companyPhone) : ''} ${
                companyExt ? companyExt : ''
              }`}</p>
            </div>
            <div
              className={classes.space}
              style={{ backgroundColor: 'white' }}
            ></div>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{`${cm_phone ? normalizePhone(cm_phone) : ''} ${
                cm_extension ? cm_extension : ''
              }`}</p>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className={classes.row}>
            <div className={classes.column}>
              <p>RE: {full_name}</p>
            </div>
            <div className={classes.space}></div>
            <div className={classes.column}>
              <p>SENDER'S FAX:</p>
            </div>
          </div>
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <div
              className={classes.shaded_column}
              style={{ padding: '3px 3px', width: '44%' }}
            >
              <p style={{ fontSize: 13, fontWeight: 'bold' }}>
                {[dobFormatted, residentIdentifierFormatted]
                  .filter(x => !!x)
                  .join(', ')}
              </p>
              <TextField
                name='residentInfo'
                value={residentInfo}
                onChange={onResidentInfoChange}
                variant='outlined'
                fullWidth
                size='small'
                placeholder='Enter Resident Identifier (Max 30 Characters)'
              />
            </div>
            <div
              className={classes.space}
              style={{ backgroundColor: 'white' }}
            ></div>
            <div
              className={classes.shaded_column}
              style={{ backgroundColor: '#bbb' }}
            >
              <p>{sender_fax ? normalizePhone(sender_fax) : ''}</p>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div>
            <p>NOTES/COMMENTS</p>
          </div>
          <p
            style={{ textAlign: 'center' }}
          >{`${address} ${city} ${state} ${zip}`}</p>
          {/* <div className={classes.box}></div> */}
          <CoverLetterTemplatesList setComment={setComment} caseId={caseId} />
          <textarea
            name='comments'
            value={comment}
            onChange={e => setComment(e.target.value)}
            className={classes.box}
            placeholder='Type here...'
            style={{ resize: 'none' }}
            maxLength={1880}
          />
          <div style={{ padding: 20 }}>
            <p style={{ fontSize: 12 }}>
              The information contained in this transmittal may include
              privileged or confidential material intended solely for the
              individual to who it is addressed. The material may also include
              information of a proprietary nature that is exempt from disclosure
              under applicable State and Federal Laws. Such disclosure is
              prohibited w/o the prior written authorization from the patient or
              the patient’s representative. If the receipt of this transmittal
              is not the intended person(s), you are notified that any
              unauthorized disclosure; distribution or duplication of this
              material is strictly prohibited.
            </p>
            <p style={{ fontSize: 15, fontWeight: 'bold' }}>
              If you receive this fax in error, please notify sender immediately
              at the number provided above and discard of wrongly received
              information by shredding.
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ paddingRight: 40, paddingBottom: 20 }}>
        {submitting && <Loading style={{ height: 'initial' }} />}
        <SaveButton onClick={submit} disabled={submitting} />
      </DialogActions>
    </Dialog>
  );
}

export function CoverLetterTemplatesList({
  setComment,
  onClick: handleClick,
  caseId,
  variant = 'outlined',
  templateType,
}) {
  const classes = useStyles();
  const [templateList, setTemplateList] = useState([]);
  const [filter, setFilter] = useState('');

  const debounceFilter = debounce(value => {
    setFilter(value);
  }, 300);

  const onChange = e => {
    debounceFilter(e.target.value);
  };

  const onClick = templateId => {
    if (handleClick) return handleClick(templateId);
    if (templateId === null || templateId === 'Add New') return;
    (async function() {
      const response = await authGet(
        `/cases/${caseId}/communication-template/${templateId}`,
      );
      const { error, data } = response;
      if (!error) {
        setComment(data.template);
      }
    })();
  };

  useEffect(() => {
    (async function() {
      if (filter.trim().length > 2) {
        const response = await authGet([
          '/communication-template/list',
          { communication_type: 'fax', q: filter, fax_type: templateType },
        ]);
        const { error, data } = response;
        if (!error) {
          setTemplateList(data);
        }
      }
    })();
  }, [filter, templateType]);

  return (
    <div>
      <Autocomplete
        onChange={onChange}
        suggestions={templateList}
        parentOnClick={onClick}
        label='Search for a template'
        classes={{ root: classes.inputRoot, input: classes.inputInput }}
        paperClassName={classes.paper}
        autoFocus
      />
    </div>
  );
}
