import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { format } from 'date-fns';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  usePermissions,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  SelectComponent,
  FileInput,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  validateDate,
} from '../modalForms';
import { DeleteRecord } from '../../../wrappers';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { constants } from '../../../../utils';
const { bedholdStatusTypes } = constants;

export const BedholdForm = ({
  caseId,
  record = {},
  isEdit,
  handleClose,
  refresh,
  closeAside,
}) => {
  const classes = useStyles();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [formData, setFormData] = useState({
    case_id: caseId,
    start_date: format(Date.now(), 'yyyy-MM-dd'),
  });
  useEffect(() => {
    if (isEdit && record) {
      const {
        id,
        status,
        authorization_id,
        created_by,
        updated_by,
        bedholdUpdatedBy,
        bedholdCreatedBy,
        ...rest
      } = record;
      setFormData(f => ({
        ...f,
        ...rest,
        bedhold_status_id: status === 'In Progress' ? 1 : 2,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('bedholds', {
          id: record.id,
          data: { ...value },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          typeof refresh === 'function' && refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('bedholds', { data: { ...value } })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          typeof refresh === 'function' && refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {!isEdit && !caseId && (
                  <div style={{ paddingBottom: 15, marginRight: 3 }}>
                    <AutocompleteInput
                      reference='cases/list'
                      customOnChange={customOnChange}
                      name='case_id'
                      label='Case'
                      validate={required()}
                      required
                      fullWidth
                      openOnFocus
                      autocompleteProps={{ openOnFocus: true }}
                      options={{
                        filter: { division: 'all', active: isEdit ? 0 : 1 },
                      }}
                    />
                  </div>
                )}
                <div className={clsx(classes.inputContainerWrap)}>
                  {!isEdit && values.case_id >= 0 && (
                    <ReferenceInput
                      reference='auth-number/list'
                      customOnChange={customOnChange}
                      name='authorization_id'
                      label='Auth number'
                      validate={required()}
                      required
                      options={{
                        filter: { case_id: values.case_id },
                      }}
                    />
                  )}
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Bedhold type'
                    validate={required()}
                    required
                    name='bedhold_type'
                    choices={bedholdTypes}
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Bedhold status'
                    validate={required()}
                    required
                    name='bedhold_status_id'
                    fullWidth
                    choices={statusTypes}
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='start_date'
                    validate={composeValidators([required(), validateDate()])}
                    required
                    customOnChange={customOnChange}
                    type='date'
                    label='Start date'
                  />
                  <FormTextField
                    name='auth_obtained_date'
                    validate={composeValidators([required(), validateDate()])}
                    required
                    customOnChange={customOnChange}
                    type='date'
                    label='Date auth obtained'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  {values.bedhold_status_id === 2 && (
                    <FormTextField
                      name='end_date'
                      customOnChange={customOnChange}
                      validate={composeValidators([required(), validateDate()])}
                      required
                      type='date'
                      label='End date'
                    />
                  )}

                  {values.bedhold_status_id === 2 && (
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Resolution'
                      validate={required()}
                      required
                      name='resolution'
                      choices={resolutionTypes}
                    />
                  )}
                </div>
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16, justifyContent: 'space-between' }}
              >
                {isEdit && userPermissions.indexOf('admin') > -1 ? (
                  <DeleteRecord
                    handleClose={handleClose}
                    resource='bedholds'
                    id={record.id}
                    record={record}
                    onClick={
                      typeof closeAside === 'function' ? closeAside : undefined
                    }
                  />
                ) : (
                  <span />
                )}
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

const bedholdTypes = [
  { id: 'therapeutic', name: 'Therapeutic' },
  { id: 'hospital', name: 'Hospital' },
];

const resolutionTypes = [
  { id: 'readmitted', name: 'Readmitted' },
  { id: 'dropped', name: 'Dropped' },
];

const statusTypes = [
  {
    id: bedholdStatusTypes.IN_PROGRESS_ID,
    name: bedholdStatusTypes.IN_PROGRESS_NAME,
  },
  { id: bedholdStatusTypes.CLOSED_ID, name: bedholdStatusTypes.CLOSED_NAME },
];
